
import { defineComponent, onMounted, reactive, ref } from "vue";
import {
  getFacilityBySpace,
  modeDictionary,
} from "@/core/services/api/facilities";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import AddSpecialAccessPermissions from "./components/AddSpecialAccessPermissions.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  components: {
    Datatable,
    AddSpecialAccessPermissions,
  },
  emits: ["onAdd"],
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader: any = reactive([
      { key: "facilityName", name: "設施名稱" },
      { key: "category", name: "設施類型" },
      { key: "mode", name: "模式" },
    ]);
    const search = ref<string>("");
    const isLoadind = ref(false);
    let addSpecialAccessPermissionsModal;

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    // 取出特殊通行facility
    const getHiddenFacility = async () => {
      let facility: any = (await getFacilityBySpace(currentSpaceGuid!))
        .map((o) => o.facility)
        .filter((o) => o.status === "Hidden");
      return facility;
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let hiddenFacility = await getHiddenFacility();
      tableData.splice(0);
      hiddenFacility.forEach((o) => {
        tableData.push({
          // 設施名稱
          facilityName: o.name,
          // 設施類型
          category: o.category,
          // 模式
          mode: modeDictionary[o.mode],
        });
      });
      initTableData.splice(0, initTableData.length, ...tableData);
      isLoadind.value = false;
    };

    const onAdd = async () => {
      addSpecialAccessPermissionsModal.hide();
      await setTableData();
    };

    onMounted(() => {
      addSpecialAccessPermissionsModal = new Modal(
        document.getElementById("addSpecialAccessPermissions-modal")
      );
    });

    const init = () => {
      setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      initTableData,
      tableSearch,
      isLoadind,
      search,
      onAdd,
    };
  },
});
