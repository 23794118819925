
import { defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";
import { addFacility } from "@/core/services/api/facilities";
import { getFacilityBySpace } from "@/core/services/api/facilities";

export default defineComponent({
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      facilityName: [
        {
          required: true,
          message: "請輸入設施名稱",
          trigger: "blur",
        },
      ],
      category: [
        { required: true, message: "請選擇設施類型", trigger: "change" },
      ],
    });
    const form = reactive({
      facilityName: "",
      category: "",
      openingHoursDescription: "",
      location: "",
      description: "",
    });
    //類型options
    const categoriesOptions =
      localstorage.getCurrentSpaces()?.space.facilitySetting.facilityCategories;

    const reseatForm = () => {
      Object.assign(form, {
        facilityName: "",
        category: "",
        openingHoursDescription: "",
        location: "",
        description: "",
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增特殊通行權限嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                space: currentSpaceGuid,
                name: form.facilityName,
                category: form.category,
                type: "Facility",
                mode: "Standard",
                status: "Hidden",
                description: form.description,
                openingHoursDescription: form.openingHoursDescription,
                location: form.location,
                displayOrder: (await getMaxFacilityDisplayOrder()) + 1,
                usageNotice: "",
                priceTag: "",
                timeSpan: 0,
                currency: "Free",
                amount: 0,
                tax: "noTax",
                overLapTargets: [],
              };
              await addFacility(request);
              emit("onAdd");
              await Swal.fire("新增成功!", "", "success");
            }
          });
        }
      });
    };

    const getMaxFacilityDisplayOrder = async () => {
      let facilityDisplayOrder = (
        await getFacilityBySpace(currentSpaceGuid!)
      ).map((o) => o.facility.displayOrder);
      return Math.max(...facilityDisplayOrder);
    };

    onMounted(() => {
      let addSpecialAccessPermissionsModal: any = document.getElementById(
        "addSpecialAccessPermissions-modal"
      );
      addSpecialAccessPermissionsModal.addEventListener(
        "hidden.bs.modal",
        function () {
          reseatForm();
          formRef.value!.resetFields();
        }
      );
    });

    return {
      rules,
      formRef,
      form,
      submit,
      categoriesOptions,
    };
  },
});
