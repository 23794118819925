<template>
  <div>
    <el-form
      @submit.prevent="submit()"
      :model="form"
      :rules="rules"
      ref="formRef"
      class="form"
    >
      <div>
        <label class="fs-6 fw-bold mb-2">
          <span class="required">設施名稱</span>
        </label>
        <el-form-item prop="facilityName">
          <el-input
            v-model="form.facilityName"
            placeholder="請輸入設施名稱"
          ></el-input>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span class="required">類型</span>
        </label>
        <el-form-item prop="category">
          <el-select
            v-model="form.category"
            placeholder="請選擇設施類型"
            style="width: 100%"
          >
            <el-option
              v-for="item in categoriesOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span>營業時間</span>
        </label>
        <el-form-item>
          <el-input
            placeholder="請輸入設施營業時間"
            v-model="form.openingHoursDescription"
          ></el-input>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span>地點</span>
        </label>
        <el-form-item>
          <el-input
            placeholder="請輸入設施地點"
            v-model="form.location"
          ></el-input>
        </el-form-item>
        <label class="fs-6 fw-bold mb-2">
          <span>簡介</span>
        </label>
        <el-form-item>
          <el-input
            type="textarea"
            :autosize="{ minRows: 2 }"
            placeholder="請輸入設施簡介"
            v-model="form.description"
          ></el-input>
        </el-form-item>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn btn-success" type="submit">送出</button>
      </div>
    </el-form>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import localstorage from "@/core/services/LocalstorageService";
import { addFacility } from "@/core/services/api/facilities";
import { getFacilityBySpace } from "@/core/services/api/facilities";

export default defineComponent({
  setup(props, { emit }) {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const formRef = ref<null | HTMLFormElement>(null);
    const rules = reactive({
      facilityName: [
        {
          required: true,
          message: "請輸入設施名稱",
          trigger: "blur",
        },
      ],
      category: [
        { required: true, message: "請選擇設施類型", trigger: "change" },
      ],
    });
    const form = reactive({
      facilityName: "",
      category: "",
      openingHoursDescription: "",
      location: "",
      description: "",
    });
    //類型options
    const categoriesOptions =
      localstorage.getCurrentSpaces()?.space.facilitySetting.facilityCategories;

    const reseatForm = () => {
      Object.assign(form, {
        facilityName: "",
        category: "",
        openingHoursDescription: "",
        location: "",
        description: "",
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          await Swal.fire({
            title: "您確定要新增特殊通行權限嗎?",
            confirmButtonText: "確定",
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request = {
                space: currentSpaceGuid,
                name: form.facilityName,
                category: form.category,
                type: "Facility",
                mode: "Standard",
                status: "Hidden",
                description: form.description,
                openingHoursDescription: form.openingHoursDescription,
                location: form.location,
                displayOrder: (await getMaxFacilityDisplayOrder()) + 1,
                usageNotice: "",
                priceTag: "",
                timeSpan: 0,
                currency: "Free",
                amount: 0,
                tax: "noTax",
                overLapTargets: [],
              };
              await addFacility(request);
              emit("onAdd");
              await Swal.fire("新增成功!", "", "success");
            }
          });
        }
      });
    };

    const getMaxFacilityDisplayOrder = async () => {
      let facilityDisplayOrder = (
        await getFacilityBySpace(currentSpaceGuid!)
      ).map((o) => o.facility.displayOrder);
      return Math.max(...facilityDisplayOrder);
    };

    onMounted(() => {
      let addSpecialAccessPermissionsModal: any = document.getElementById(
        "addSpecialAccessPermissions-modal"
      );
      addSpecialAccessPermissionsModal.addEventListener(
        "hidden.bs.modal",
        function () {
          reseatForm();
          formRef.value!.resetFields();
        }
      );
    });

    return {
      rules,
      formRef,
      form,
      submit,
      categoriesOptions,
    };
  },
});
</script>

<style>
</style>