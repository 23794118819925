<template>
  <div class="card">
    <div class="card-header border-0 pt-6 row">
      <!--begin::Card title-->
      <div class="row col d-flex">
        <div
          class="
            col-lg-3 col-12
            d-flex
            align-items-center
            position-relative
            my-1
          "
        >
          <span class="svg-icon svg-icon-1 position-absolute ms-6">
            <inline-svg src="media/icons/duotune/general/gen021.svg" />
          </span>
          <input
            type="text"
            v-model="search"
            @input="tableSearch()"
            class="form-control form-control-solid ps-15"
            placeholder="搜尋"
          />
        </div>
        <div class="col d-flex align-items-center justify-content-end">
          <button
            class="btn btn-success"
            data-bs-toggle="modal"
            data-bs-target="#addSpecialAccessPermissions-modal"
          >
            新增
          </button>
        </div>
      </div>
    </div>
    <div class="card-body pt-0">
      <div v-if="isLoadind">
        <span
          class="spinner-grow spinner-grow"
          style="width: 1rem; height: 1rem"
          role="status"
          aria-hidden="true"
        ></span>
        <i style="font-size: 16px">正在載入...</i>
      </div>
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
        :openTextEnd="false"
        :striped="true"
      >
        <template v-slot:cell-facilityName="{ row: tableData }">
          {{ tableData.facilityName }}
        </template>
        <template v-slot:cell-category="{ row: tableData }">
          {{ tableData.category }}
        </template>
        <template v-slot:cell-mode="{ row: tableData }">
          {{ tableData.mode }}
        </template>
      </Datatable>
    </div>
  </div>
  <!-- 新增特殊通行權限modal -->
  <div
    class="modal fade"
    id="addSpecialAccessPermissions-modal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            class="modal-title"
            id="addSpecialAccessPermissions-exampleModalLabel"
          >
            新增特殊通行權限
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <AddSpecialAccessPermissions
            @onAdd="onAdd()"
          ></AddSpecialAccessPermissions>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, onMounted, reactive, ref } from "vue";
import {
  getFacilityBySpace,
  modeDictionary,
} from "@/core/services/api/facilities";
import localstorage from "@/core/services/LocalstorageService";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import { searchItems } from "@/core/utils/tableSearch";
import AddSpecialAccessPermissions from "./components/AddSpecialAccessPermissions.vue";
import { Modal } from "bootstrap";

export default defineComponent({
  components: {
    Datatable,
    AddSpecialAccessPermissions,
  },
  emits: ["onAdd"],
  setup() {
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const tableData: any = reactive([]);
    const initTableData: any = reactive([]);
    const tableHeader: any = reactive([
      { key: "facilityName", name: "設施名稱" },
      { key: "category", name: "設施類型" },
      { key: "mode", name: "模式" },
    ]);
    const search = ref<string>("");
    const isLoadind = ref(false);
    let addSpecialAccessPermissionsModal;

    const tableSearch = () => {
      tableData.splice(0, tableData.length, ...initTableData);
      tableData.splice(
        0,
        tableData.length,
        ...searchItems(search.value, tableData)
      );
    };

    // 取出特殊通行facility
    const getHiddenFacility = async () => {
      let facility: any = (await getFacilityBySpace(currentSpaceGuid!))
        .map((o) => o.facility)
        .filter((o) => o.status === "Hidden");
      return facility;
    };

    const setTableData = async () => {
      isLoadind.value = true;
      let hiddenFacility = await getHiddenFacility();
      tableData.splice(0);
      hiddenFacility.forEach((o) => {
        tableData.push({
          // 設施名稱
          facilityName: o.name,
          // 設施類型
          category: o.category,
          // 模式
          mode: modeDictionary[o.mode],
        });
      });
      initTableData.splice(0, initTableData.length, ...tableData);
      isLoadind.value = false;
    };

    const onAdd = async () => {
      addSpecialAccessPermissionsModal.hide();
      await setTableData();
    };

    onMounted(() => {
      addSpecialAccessPermissionsModal = new Modal(
        document.getElementById("addSpecialAccessPermissions-modal")
      );
    });

    const init = () => {
      setTableData();
    };
    init();

    return {
      tableData,
      tableHeader,
      initTableData,
      tableSearch,
      isLoadind,
      search,
      onAdd,
    };
  },
});
</script>

<style>
</style>